/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { Link } from "react-router-dom";
import Wrapper from "components/Wrapper";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";

import styles from "./styles.scss";

type Props = {
  className: string,
  setToggleCookieDialogue: (value: boolean) => void,
};

const AppFooter = ({ className = "", setToggleCookieDialogue }: Props): React$Node => {
  const { categories } = useContext(StoreInfoContext);
  const t = useTranslate();

  return (
    <div className={cn(styles.block, className)}>
      <div className={styles.categoriesWrapper}>
        <Wrapper className={cn(styles.wrapper)}>
          <div className={styles.categories}>
            {categories.map(x => (
              <div key={x.name} className={styles.category}>
                <Link
                  className={cn("h3", styles.categoryLink)}
                  to={{
                    pathname: x.url,
                    state: { hint: { type: "category", category: x } },
                  }}
                >
                  <span>{x.name}</span>
                </Link>

                {x.children.map(c => (
                  <div key={c.name} className={styles.subCategory}>
                    <Link
                      className={styles.subCategoryLink}
                      to={{
                        pathname: c.url,
                        state: { hint: { type: "category", category: c } },
                      }}
                    >
                      <span>{c.name}</span>
                    </Link>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Wrapper>
      </div>

      <div className={styles.linksWrapper}>
        <Wrapper className={styles.wrapper}>
          <ul className={styles.links}>
            <li><Link to={t("CUSTOMER_SERVICE.LINK")}>{t("CUSTOMER_SERVICE.TEXT")}</Link></li>
            <li><Link to={t("FOOTER.LINKS.TERMS.LINK")}>{t("FOOTER.LINKS.TERMS.TEXT")}</Link></li>
            <li><Link to={t("FOOTER.LINKS.PRIVACY_POLICY.LINK")}>{t("FOOTER.LINKS.PRIVACY_POLICY.TEXT")}</Link></li>
            <li>
              <Button
                className={styles.link}
                onClick={() => setToggleCookieDialogue(true)}
              >
                {t("FOOTER.NEED_HELP.COOKIES")}
              </Button>
            </li>
          </ul>
        </Wrapper>
      </div>

      <div className={styles.copyright}>
        <Wrapper className={styles.copyrightWrapper}>
          <p>{t("FOOTER.COPYRIGHT", { year: new Date().getFullYear() })}</p>
          <p dangerouslySetInnerHTML={{ __html: t("FOOTER.LEGAL", { year: new Date().getFullYear() }) }} />
        </Wrapper>
      </div>
    </div>
  );
};

export default AppFooter;
