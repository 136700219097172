/* @flow */

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { Link, withRouter } from "react-router-dom";
import { HomeData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { useBreakpoints } from "helpers/use-breakpoints";
import { formatMeta } from "helpers/get-meta";
import { useUi } from "helpers/ui";
import Wrapper from "components/Wrapper";
import Hero, { BlockSplitHero, BlockHero } from "components/Hero";
import Button from "components/Button";
import ProductList from "components/ProductList";
import LoadingView from "components/LoadingView";
import { MODE } from "state/view-mode";
import Categories from "./Categories";

import styles from "./styles.scss";

const HomeView = () => {
  const t = useTranslate();
  const home = useData(HomeData);
  const {
    info: { defaultTitle, defaultDescription },
  } = useContext(StoreInfoContext);
  const { getNumberOfDisplayedItems } = useBreakpoints(styles);
  const { setViewMode } = useUi();

  if (home.state !== "LOADED") {
    return <LoadingView />;
  }

  const categoriesData = (Object.values(home.data): any)
    .filter(e => e && Object.prototype.hasOwnProperty.call(e, "carousel"))
    .map(c => ({
      name: c.carousel.name,
      url: c.carousel.url,
      image: { x1: c.carousel.image.x1, x2: c.carousel.image.x2 },
    }));

  const meta = formatMeta({
    title: defaultTitle,
    description: defaultDescription,
  });

  const getPopularProductAmount = () => getNumberOfDisplayedItems({ mini: 3, small: 4 }, 3);

  return (
    <div className={styles.block}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      {home.data.hero &&
        <section className={styles.row}>
          <Hero
            wrapContent
            className={styles.hero1}
            block={home.data.hero.block}
            buttonModifier="primary"
          />
        </section>
      }

      <Wrapper>
        <section className={styles.row}>
          <div className={styles.popularCategories}>
            <header>
              <h2>{t("HOMEVIEW.POPULAR_CATEGORIES")}</h2>
              <span
                className={styles.ctaDesktop}
                onClick={() => setViewMode(MODE.CATEGORIES)}
              >
                {t("HOMEVIEW.SEE_MORE_POPULAR_CATEGORIES")}
              </span>
            </header>

            <Categories className={styles.categories} items={categoriesData} />

            <Button
              className={styles.ctaMobile}
              variant="secondary"
              onClick={() => setViewMode(MODE.CATEGORIES)}
            >
              {t("HOMEVIEW.SEE_MORE_POPULAR_CATEGORIES")}
            </Button>
          </div>
        </section>

        {(home.data.block1FullWidth &&
          home.data.block1FullWidth.block &&
          home.data.block1FullWidth.block.heading) ? (
            <section className={styles.row}>
              <BlockHero
                className={styles.blockHero}
                wrapperClass={styles.blockHeroWrapper}
                block={home.data.block1FullWidth.block}
                buttonModifier="primary"
              >
                <h2 className={styles.heroHeading}>
                  {home.data.block1FullWidth.block.heading}
                </h2>
              </BlockHero>
            </section>
          ) : null
        }

        {(home.data.block2Left && home.data.block2Middle && home.data.block2Right) &&
          <section className={styles.row}>
            <div className={styles.col2}>
              <div className={styles.column}>
                <BlockSplitHero
                  className={styles.blockSplitHero}
                  block={home.data.block2Left.block}
                />
              </div>
              <div className={styles.column}>
                <BlockSplitHero
                  className={styles.blockSplitHero}
                  block={home.data.block2Middle.block}
                />
              </div>
              <div className={styles.column}>
                <BlockSplitHero
                  className={styles.blockSplitHero}
                  block={home.data.block2Right.block}
                />
              </div>
            </div>
          </section>
        }

        {home.data.featuredProducts &&
          <div className={styles.popularProducts}>
            <header>
              <h2>{t("HOMEVIEW.POPULAR_PRODUCTS")}</h2>
              <Link
                className={styles.ctaDesktop}
                to={{
                  pathname: home.data.featuredProducts.category.url,
                  state: { hint: {
                    type: "category",
                    category: {
                      name: home.data.featuredProducts.category.name,
                    },
                  } },
                }}
              >
                <span>{t("HOMEVIEW.SEE_MORE_POPULAR_PRODUCTS")}</span>
              </Link>
            </header>
            <ProductList
              products={home.data.featuredProducts.category.products.items.slice(
                0,
                getPopularProductAmount()
              )}
              productsPerRow={3}
            />

            <Link
              to={{
                pathname: home.data.featuredProducts.category.url,
                state: { hint: {
                  type: "category",
                  category: {
                    name: home.data.featuredProducts.category.name,
                  },
                } },
              }}
            >
              <Button
                className={styles.ctaMobile}
                variant="secondary"
              >
                {t("HOMEVIEW.SEE_MORE_POPULAR_PRODUCTS")}
              </Button>
            </Link>
          </div>
        }

        {home.data.recentlyViewedProducts && home.data.recentlyViewedProducts.items.length > 0 &&
          <div className={styles.recentlyViewedProducts}>
            <h2>{t("HOMEVIEW.RECENTLY_VIEWED_PRODUCTS")}</h2>
            <ProductList
              products={
                home.data.recentlyViewedProducts?.items.slice(0, getPopularProductAmount())
              }
              productsPerRow={3}
            />
          </div>
        }
      </Wrapper>

    </div>
  );
};

export default withRouter(HomeView);
