/* @flow */

import type { BlockSplit, HeroBlockCategory, SubHeroBlockCategory } from "state/home";

import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Button from "components/Button";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";

type BlockProps = {
  className?: string,
  wrapContent?: boolean,
  block?: SubHeroBlockCategory,
};

type HeroProps = {
  buttonModifier?: string,
  className?: string,
  wrapContent?: boolean,
  block: HeroBlockCategory,
};

type BlockSplitHeroProps = {
  className?: string,
  wrapContent?: boolean,
  block: BlockSplit,
};

const Hero = ({
  className,
  block,
  buttonModifier = "primary",
  wrapContent = false,
}: HeroProps) => {
  const Wrap = wrapContent ? Wrapper : "div";

  const images = [
    {
      media: `(min-width: ${styles.large}px)`,
      srcSet: block.largeImage ? `
          ${block.largeImage.w1280} 1280w,
          ${block.largeImage.w1440} 1440w,
          ${block.largeImage.w1600} 1600w,
          ${block.largeImage.w1920} 1920w,
          ${block.largeImage.w2560} 2560w,
        ` : "",
    },
    {
      media: `(min-width: ${styles.medium}px)`,
      srcSet: block.mediumImage ? `${block.mediumImage.x1}, ${block.mediumImage.x2} 2x` : "",
    },
    {
      media: `(min-width: 0px)`,
      srcSet: block.smallImage ? `${block.smallImage.x1}, ${block.smallImage.x2} 2x` : "",
    },
  ].filter(blurImage => blurImage.srcSet);

  return (
    <div
      className={cn(
        styles.block,
        { [styles.block__wrap]: wrapContent },
        className
      )}
      style={{ backgroundColor: block.modifier }}
    >
      <picture className={styles.image} alt={block.heading}>
        {images.map(({ srcSet, media }) => (
          <source
            key={srcSet}
            srcSet={srcSet}
            media={media}
          />
        ))}

        <img
          alt={block.heading}
          draggable="false"
          src={block.largeImage ? block.largeImage.w1280 : ""}
          data-object-fit="cover"
        />
      </picture>

      <Wrap className={styles.contentWrapper}>
        {block.heading &&
          <h1>{block.heading}</h1>
        }
        {block.text &&
          <h2>{block.text}</h2>
        }
        {block.link && block.cta &&
          <Button
            to={block.link}
            variant={buttonModifier}
            className={styles.cta}
          >
            {block.cta}
          </Button>
        }
      </Wrap>
    </div>
  );
};

export const BlockSplitHero = ({
  className,
  block,
  wrapContent = false,
}: BlockSplitHeroProps) => {
  const Wrap = wrapContent ? Wrapper : "div";

  const images = [
    {
      media: `(min-width: ${styles.small}px)`,
      srcSet: `${block.image ? `${block.image.x1}, ${block.image.x2} 2x` : ""}`,
    },
    {
      media: "(min-width: 0px)",
      srcSet: `${block.imageSmall ? `${block.imageSmall.x1}, ${block.imageSmall.x2} 2x` : ""}`,
    },
  ].filter(blurImage => blurImage.srcSet);

  return (
    <div
      className={cn(
        styles.block,
        styles.blockSplitHero,
        { [styles.block__wrap]: wrapContent },
        className
      )}
      style={{ backgroundColor: block.modifier }}
    >
      <picture className={styles.image} alt={block.heading}>
        {images.map(({ srcSet, media }) => (
          <source
            key={srcSet}
            srcSet={srcSet}
            media={media}
          />
        ))}

        <img
          alt={block.heading}
          draggable="false"
          src={block.image ? block.image.x1 : ""}
          data-object-fit="cover"
        />
      </picture>

      <Wrap className={styles.spacer}>
        <div className={styles.contentWrapper}>
          {block.heading &&
            <h2>{block.heading}</h2>
          }
          {block.text &&
            <h2>{block.text}</h2>
          }
          {block.link && block.cta &&
          <Button
            to={block.link}
            variant="secondary"
            className={styles.ctaLink}
          >
            {block.cta}
          </Button>}
        </div>
      </Wrap>
    </div>
  );
};

export const BlockHero = ({
  className,
  block,
  wrapContent = false,
}: BlockProps) => {
  if (!block || !block.largeImage) {
    return null;
  }

  const Wrap = wrapContent ? Wrapper : "div";
  const images = [
    {
      media: `(min-width: ${styles.large}px)`,
      srcSet: block.largeImage ? `${block.largeImage.x1} x1, ${block.largeImage.x2} x2` : "",
    },
    {
      media: `(min-width: ${styles.medium}px)`,
      srcSet: block.mediumImage ? `${block.mediumImage.x1}, ${block.mediumImage.x2} 2x` : "",
    },
    {
      media: `(max-width: ${styles.tiny}px)`,
      srcSet: block.smallImage ? `${block.smallImage.x1}, ${block.smallImage.x2} 2x` : "",
    },
  ].filter(blurImage => blurImage.srcSet);

  return (
    <div
      className={cn(
        styles.block,
        { [styles.block__wrap]: wrapContent },
        className
      )}
      style={{ backgroundColor: block.modifier }}
    >
      <Link className={styles.blockLink} to={block.link}>
        <picture className={styles.image} alt={block.heading}>
          {images.map(({ srcSet, media }) => (
            <source
              key={srcSet}
              srcSet={srcSet}
              media={media}
            />
          ))}

          <img
            alt={block.heading}
            draggable="false"
            src={block.largeImage ? block.largeImage.x1 : ""}
            data-object-fit="cover"
          />
        </picture>

        <Wrap className={styles.contentWrapper}>
          {block.ribbon &&
            <div className={styles.campaign}>
              <span>{block.ribbon}</span>
            </div>
          }
          <div className={styles.textWrapper}>
            {block.heading &&
              <h2>{block.heading}</h2>
            }
            {block.text &&
              <h2>{block.text}</h2>
            }
          </div>
        </Wrap>
      </Link>
    </div>
  );
};

export default Hero;
