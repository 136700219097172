/* @flow */

import type { ProductCardProduct } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

import { REFRESH_PRODUCT_DATA } from "state/customer";

type AbstractBlock = {
  heading: string,
  text: string,
  cta: string,
  link: string,
  modifier: string,
  ribbon: string,
};

type Carousel = {
  image: { x1: string, x2: string },
  name: string,
  url: string,
};

type AdventCalendarImages = {
  clsCatfieldsGeneralImgL: ?{ x1: string, x2: string },
};

export type HeroBlockCategory = AbstractBlock & {
  smallImage?: { x1: string, x2: string },
  mediumImage?: { x1: string, x2: string },
  largeImage?: {
    w1280: string,
    w1440: string,
    w1600: string,
    w1920: string,
    w2560: string,
  },
};

export type SubHeroBlockCategory = AbstractBlock & {
  smallImage?: { x1: string, x2: string },
  mediumImage?: { x1: string, x2: string },
  largeImage?: { x1: string, x2: string },
};

export type BlockSplit = AbstractBlock & {
  image?: { x1: string, x2: string },
  imageSmall?: { x1: string, x2: string },
};

export type Home = {
  featuredProducts: {
    category: {
      name: string,
      url: string,
      products: {
        totalCount: number,
        items: Array<ProductCardProduct>,
      },
    },
  },
  recentlyViewedProducts: ?{
    items: Array<ProductCardProduct>,
  },
  hero?: {
    block: HeroBlockCategory,
  },
  block1FullWidth?: {
    block: SubHeroBlockCategory,
  },
  block2Left?: {
    block: BlockSplit,
  },
  block2Middle?: {
    block: BlockSplit,
  },
  block2Right?: {
    block: BlockSplit,
  },
  c1: {
    carousel?: Carousel,
  },
  c2: {
    carousel?: Carousel,
  },
  c3: {
    carousel?: Carousel,
  },
  c4: {
    carousel?: Carousel,
  },
  c5: {
    carousel?: Carousel,
  },
  c6: {
    carousel?: Carousel,
  },
  c7: {
    carousel?: Carousel,
  },
  c8: {
    carousel?: Carousel,
  },
  adventCalendar?: {
    category: AdventCalendarImages,
  },
};

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: Home }
  | { state: "ERROR", error: string };

export type HomeResponse = Response<typeof HOME_RESPONSE, ?Home>;

export type HomeRequest = {
  tag: typeof HOME_REQUEST,
  type: "home",
};

export const HOME_RESPONSE: "response/home" = "response/home";
export const HOME_REQUEST: "request/home" = "request/home";

export const HomeModel: Model<Data, { }, HomeRequest | HomeResponse> = {
  id: "home",
  init: () =>
    updateData(
      { state: "LOADING", type: "home" },
      { tag: HOME_REQUEST, type: "home" }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case HOME_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      case REFRESH_PRODUCT_DATA:
        return updateData(
          { state: "LOADING", type: "home" },
          { tag: HOME_REQUEST, type: "home" }
        );

      default:
    }
  },
};
